<script setup lang="ts">
const localePath = useLocalePath()
defineProps<{
    error: {
        statusCode: number
        message: string
    }
}>()
</script>
<template>
    <div class="error-page">
        <div class="error-page__inner">
            <Svgo404 class="w-full max-w-lg mx-auto mb-14" />
            <h1 class="mb-6">{{ error.statusCode === 404 ? $t('error.404') : $t('error.oops') }}</h1>
            <p class="mb-6">{{ error.message }}</p>
            <NuxtLink :to="localePath('/')" class="link-btn link-btn--main mx-auto">{{ $t('action.homepage') }}</NuxtLink>
        </div>
    </div>
</template>

<style scoped lang="scss">
.error-page {
    @apply px-4 h-screen flex items-center justify-center text-center;
    &__inner {
        @apply w-full;
    }
}
</style>