import revive_payload_client_4sVQNw7RlN from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/vozilo/vozilo-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/builds/vozilo/vozilo-web/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_5drvKYmdqw from "/builds/vozilo/vozilo-web/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_kB5Aw4bt3s from "/builds/vozilo/vozilo-web/node_modules/nuxt-module-hotjar/dist/runtime/plugin.client.mjs";
import plugin_client_LcKgStRyi6 from "/builds/vozilo/vozilo-web/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/builds/vozilo/vozilo-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/vozilo/vozilo-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import google_recaptcha_rYK9Kziv68 from "/builds/vozilo/vozilo-web/plugins/google-recaptcha.ts";
import libs_birIii1sow from "/builds/vozilo/vozilo-web/plugins/libs.ts";
import sentry_3AyO8nEfhE from "/builds/vozilo/vozilo-web/plugins/sentry.ts";
import toastify_ScRgZgP9op from "/builds/vozilo/vozilo-web/plugins/toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  plugin_5drvKYmdqw,
  plugin_client_kB5Aw4bt3s,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  google_recaptcha_rYK9Kziv68,
  libs_birIii1sow,
  sentry_3AyO8nEfhE,
  toastify_ScRgZgP9op
]