import { default as indexuvxkbmFnPCMeta } from "/builds/vozilo/vozilo-web/pages/index.vue?macro=true";
import { default as privacy_45policyuGE0cZg0oJMeta } from "/builds/vozilo/vozilo-web/pages/privacy-policy.vue?macro=true";
import { default as terms_45conditions6qIm6hrGl9Meta } from "/builds/vozilo/vozilo-web/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: indexuvxkbmFnPCMeta?.name ?? "index___hr",
    path: indexuvxkbmFnPCMeta?.path ?? "/",
    meta: indexuvxkbmFnPCMeta || {},
    alias: indexuvxkbmFnPCMeta?.alias || [],
    redirect: indexuvxkbmFnPCMeta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuvxkbmFnPCMeta?.name ?? "index___en",
    path: indexuvxkbmFnPCMeta?.path ?? "/en",
    meta: indexuvxkbmFnPCMeta || {},
    alias: indexuvxkbmFnPCMeta?.alias || [],
    redirect: indexuvxkbmFnPCMeta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyuGE0cZg0oJMeta?.name ?? "privacy-policy___hr",
    path: privacy_45policyuGE0cZg0oJMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyuGE0cZg0oJMeta || {},
    alias: privacy_45policyuGE0cZg0oJMeta?.alias || [],
    redirect: privacy_45policyuGE0cZg0oJMeta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyuGE0cZg0oJMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyuGE0cZg0oJMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policyuGE0cZg0oJMeta || {},
    alias: privacy_45policyuGE0cZg0oJMeta?.alias || [],
    redirect: privacy_45policyuGE0cZg0oJMeta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditions6qIm6hrGl9Meta?.name ?? "terms-conditions___hr",
    path: terms_45conditions6qIm6hrGl9Meta?.path ?? "/terms-conditions",
    meta: terms_45conditions6qIm6hrGl9Meta || {},
    alias: terms_45conditions6qIm6hrGl9Meta?.alias || [],
    redirect: terms_45conditions6qIm6hrGl9Meta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditions6qIm6hrGl9Meta?.name ?? "terms-conditions___en",
    path: terms_45conditions6qIm6hrGl9Meta?.path ?? "/en/terms-conditions",
    meta: terms_45conditions6qIm6hrGl9Meta || {},
    alias: terms_45conditions6qIm6hrGl9Meta?.alias || [],
    redirect: terms_45conditions6qIm6hrGl9Meta?.redirect,
    component: () => import("/builds/vozilo/vozilo-web/pages/terms-conditions.vue").then(m => m.default || m)
  }
]