import { defineNuxtPlugin } from '#app/nuxt'
import { LazyUiButton, LazyUiCard, LazyUiCheckbox, LazyUiFormItem, LazyUiInput, LazyUiSelect, LazyUiTag, LazyUiTextarea, LazyUiToggle, LazySvgo404, LazySvgoAppStore, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoBa, LazySvgoChevronDown, LazySvgoClipboardCheck, LazySvgoClipboard, LazySvgoCodeMerge, LazySvgoCreditCard, LazySvgoDiscount, LazySvgoEnvelope, LazySvgoFaceGrinStars, LazySvgoFacebook, LazySvgoFileChartBar, LazySvgoFileShield, LazySvgoFire, LazySvgoGb, LazySvgoGithub, LazySvgoGooglePlay, LazySvgoHr, LazySvgoInstagram, LazySvgoLogo, LazySvgoMapPin, LazySvgoMessageCaption, LazySvgoMk, LazySvgoPhone, LazySvgoReceipt, LazySvgoRs, LazySvgoSearch, LazySvgoStore, LazySvgoTiktok, LazySvgoTruck, LazySvgoTwitter, LazySvgoUs, LazySvgoUser, LazyCookieControl, LazyCookieIframe } from '#components'
const lazyGlobalComponents = [
  ["UiButton", LazyUiButton],
["UiCard", LazyUiCard],
["UiCheckbox", LazyUiCheckbox],
["UiFormItem", LazyUiFormItem],
["UiInput", LazyUiInput],
["UiSelect", LazyUiSelect],
["UiTag", LazyUiTag],
["UiTextarea", LazyUiTextarea],
["UiToggle", LazyUiToggle],
["Svgo404", LazySvgo404],
["SvgoAppStore", LazySvgoAppStore],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBa", LazySvgoBa],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoClipboardCheck", LazySvgoClipboardCheck],
["SvgoClipboard", LazySvgoClipboard],
["SvgoCodeMerge", LazySvgoCodeMerge],
["SvgoCreditCard", LazySvgoCreditCard],
["SvgoDiscount", LazySvgoDiscount],
["SvgoEnvelope", LazySvgoEnvelope],
["SvgoFaceGrinStars", LazySvgoFaceGrinStars],
["SvgoFacebook", LazySvgoFacebook],
["SvgoFileChartBar", LazySvgoFileChartBar],
["SvgoFileShield", LazySvgoFileShield],
["SvgoFire", LazySvgoFire],
["SvgoGb", LazySvgoGb],
["SvgoGithub", LazySvgoGithub],
["SvgoGooglePlay", LazySvgoGooglePlay],
["SvgoHr", LazySvgoHr],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLogo", LazySvgoLogo],
["SvgoMapPin", LazySvgoMapPin],
["SvgoMessageCaption", LazySvgoMessageCaption],
["SvgoMk", LazySvgoMk],
["SvgoPhone", LazySvgoPhone],
["SvgoReceipt", LazySvgoReceipt],
["SvgoRs", LazySvgoRs],
["SvgoSearch", LazySvgoSearch],
["SvgoStore", LazySvgoStore],
["SvgoTiktok", LazySvgoTiktok],
["SvgoTruck", LazySvgoTruck],
["SvgoTwitter", LazySvgoTwitter],
["SvgoUs", LazySvgoUs],
["SvgoUser", LazySvgoUser],
["CookieControl", LazyCookieControl],
["CookieIframe", LazyCookieIframe],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
