import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-left",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#252525",
    "barButtonBackground": "#F98521",
    "barButtonColor": "#FFFFFF",
    "barButtonHoverBackground": "#F36515",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [],
    "optional": []
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/",
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": false,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": false,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use cookie to improve your experience on our site. By using our site you consent cookies.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "hr": {
      "bannerTitle": "Kolačići",
      "bannerDescription": "Koristimo kolačiće kako bismo poboljšali vaše iskustvo na našoj stranici. Korištenjem naše stranice pristajete na kolačiće.",
      "accept": "Prihvati"
    }
  }
} as ModuleOptions