const fallback = {
    title: 'Nikad jednostavniji transport',
    site_name: 'Vozi.lo'
};

// Setup base meta tags.
const setupMetaTags = (
    title: string | null,
    description: string | null,
    url: string | null,
    img: string | null,
) => {

    const dirtyUrl = useRequestURL()
    url ||= (dirtyUrl.origin + dirtyUrl.pathname);
    const canonical = url

    title ||= fallback.title
    const site_name = fallback.site_name

    img = useRequestURL().origin + '/images/OG-image.png'

    return ({
        title,
        titleTemplate: '%s',
        meta: [
            {
                name: 'description',
                content: description || '',
            }
            ,
            /* Open Graph */
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:site_name',
                content: site_name,
            },
            {
                property: 'og:url',
                content: url,
            },
            {
                property: 'og:description',
                content: description || '',
            },
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:image',
                content: img,
            },
            {
                property: 'og:image:width',
                content: '1200',
            },
            {
                property: 'og:image:height',
                content: '630',
            },
            /* Twitter */
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:site', content: site_name },
            {
                property: 'twitter:title',
                content: title,
            },
            {
                property: 'twitter:description',
                content: description,
            },
            {
                property: 'twitter:url',
                content: url,
            },
            {
                property: 'twitter:image',
                content: img,
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: canonical,
            },
            {
                rel: 'icon',
                type: 'image/x-icon',
                sizes: 'any',
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '512x512',
                href: `/favicons/android-chrome-512x512.png`,
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: `/favicons/android-chrome-192x192.png`,
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: `/favicons/android-chrome-192x192.png`,
            },
            {
                type: 'image/png',
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: `/favicons/apple-touch-icon.png`,
            },
            {
                type: 'image/png',
                rel: 'icon',
                sizes: '32x32',
                href: `/favicons/favicon-32x32.png`,
            },
            {
                type: 'image/png',
                rel: 'icon',
                sizes: '16x16',
                href: `/favicons/favicon-16x16.png`,
            },
            { rel: 'manifest', href: '/favicons/site.webmanifest' },
        ],
    });
}

export default { setupMetaTags }